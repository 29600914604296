import { FC, ReactNode, useCallback, useMemo, useState } from 'react'
import { Provider } from './HeaderSizeContext'

interface HeaderSizeContextProviderProps {
  children?: ReactNode
}

const HeaderSizeContextProvider: FC<HeaderSizeContextProviderProps> = ({
  children,
}) => {
  const [headerHeight, setHeight] = useState<number>(0)

  const setHeaderHeight = useCallback(
    (height: number) => setHeight(height),
    []
  )

  const contextData = useMemo(() => ({
    headerHeight,
    setHeaderHeight,
  }), [headerHeight, setHeaderHeight])

  return (
    <Provider value={contextData}>
      {children}
    </Provider>
  )
}

export default HeaderSizeContextProvider
