import { FC } from 'react'
import { MdMenu } from 'react-icons/md'
import Styles from './DrawerCloseButtonProps.module.sass'

interface DrawerCloseButtonProps {
  onClick: () => void,
}

const DrawerCloseButton: FC<DrawerCloseButtonProps> = ({
  onClick,
}) => (
  <button
    className={Styles.button}
    onClick={(e) => {
      e.preventDefault()
      onClick()
    }}
  >
    <MdMenu /> Menü schließen
  </button>
)

export default DrawerCloseButton
