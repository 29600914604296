import { FC, ReactNode } from 'react'
import { useHeaderSize } from 'services/Header'
import Styles from './Main.module.sass'

interface MainProps {
  children?: ReactNode,
}

const Main: FC<MainProps> = ({ children }) => {
  const { headerHeight } = useHeaderSize()

  return (
    <main className={Styles.main} style={{ marginTop: `${headerHeight}px` }}>
      {children}
    </main>
  )
}

export default Main
