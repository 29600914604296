import {
  FC,
} from 'react'
import {
  FormikProvider,
  Form,
} from 'formik'
import {
  Alert,
  Button,
  Col,
  FormCaptcha,
  FormCheckbox,
  FormInput,
  FormTextarea,
  LoadingIndicator,
  Row,
} from 'atoms'
import { ContactFormProps } from './Types'
import Success from './Success'
import withData from './withData'
import Styles from './ContactForm.module.sass'

const ContactForm: FC<ContactFormProps> = ({
  formik,
  genericError,
  captchaRef,
  submitted,
}) => (
  <div>
    {submitted ? (<Success />) : (
      <FormikProvider value={formik}>
        <Form>
          <FormInput
            name="email"
            label="E-Mail-Adresse"
            type="email"
            required
            disabled={formik.isSubmitting}
          />
          <p className={Styles.note}>
            {/* eslint-disable-next-line max-len */}
            Bitte teilen Sie mir mit, wie sich auf mich aufmerksam geworden sind und was für Sie im Voraus wichtig ist:
          </p>
          <FormTextarea
            name="message"
            label="Nachricht"
            rows={5}
            disabled={formik.isSubmitting}
            wrapperClassName={Styles.message}
          />
          <FormInput
            name="name"
            label="Name"
            type="text"
            disabled={formik.isSubmitting}
          />
          <FormInput
            name="city"
            label="Heimatstadt"
            type="text"
            disabled={formik.isSubmitting}
          />
          <FormInput
            name="phone"
            label="Telefon"
            type="tel"
            disabled={formik.isSubmitting}
          />
          <FormInput
            name="mobile"
            label="Handy"
            type="tel"
            disabled={formik.isSubmitting}
          />
          <p className={Styles.note}>
              Bitte kontaktieren Sie mich.
          </p>
          <p className={Styles.subNote}>
              (Zutreffendes bitte ankreuzen)
          </p>
          <Row>
            <Col xs={12} md={4} lg={3} offsetLg={1}>
              <FormCheckbox
                name="responseCall"
                label="Ich bitte um Ihren Anruf:"
                disabled={formik.isSubmitting}
              />
            </Col>
            <Col xs={12} md={8} lg={7}>
              <Row>
                <Col sm={4}>
                  <FormCheckbox
                    name="responseTime[morning]"
                    label="Vormittags"
                    disabled={formik.isSubmitting}
                  />
                </Col>
                <Col sm={4}>
                  <FormCheckbox
                    name="responseTime[afternoon]"
                    label="Nachmittags"
                    disabled={formik.isSubmitting}
                  />
                </Col>
                <Col sm={4}>
                  <FormCheckbox
                    name="responseTime[evenings]"
                    label="Abends"
                    disabled={formik.isSubmitting}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <FormCheckbox
                    name="responseTime[weekday]"
                    label="Wochentags"
                    disabled={formik.isSubmitting}
                  />
                </Col>
                <Col sm={8}>
                  <FormCheckbox
                    name="responseTime[weekends]"
                    label="am Wochenende"
                    disabled={formik.isSubmitting}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <FormCaptcha
            name="captcha"
            ref={captchaRef}
          />
          <Row>
            <Col xs={12} md={8} lg={6} offsetMd={2} offsetLg={3}>
              {formik.isSubmitting && (
                <div className="text-center">
                  <LoadingIndicator />
                </div>
              )}
              {genericError && (
                <Alert type="error">
                  {genericError}
                </Alert>
              )}
              <Button
                type="submit"
                block
                disabled={formik.isSubmitting}
              >
                  Senden
              </Button>
            </Col>
          </Row>
        </Form>
      </FormikProvider>
    )}
  </div>
)

export default withData(ContactForm)
