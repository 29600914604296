import { FC } from 'react'
import { BsPatchCheckFill } from 'react-icons/bs'
import Styles from './Success.module.sass'

const Success: FC<unknown> = () => (
  <div className={Styles.wrapper}>
    <div className={Styles.header}>
      <BsPatchCheckFill className={Styles.icon} />
      Anfrage gesendet
    </div>
    <p>
      {/* eslint-disable-next-line max-len */}
      Vielen Dank für Ihre Anfrage. Bitte haben Sie Verständnis dafür, dass ich mich während laufender Coachings nicht zurückmelden kann. Ich setze alles dara, mich umgehend Ihrem Anliegen zuzuwenden.
    </p>
  </div>
)

export default Success
