import { format } from 'date-fns'
import { de } from 'date-fns/locale'

function dateFormat(
  date: Date | number,
  formatStr: string,
  options?: {
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
    firstWeekContainsDate?: number
    useAdditionalWeekYearTokens?: boolean
    useAdditionalDayOfYearTokens?: boolean
  }
) {
  return format(date, formatStr, { ...options, locale: de })
}

export default dateFormat
