import { createContext } from 'react'

export interface IContactModalContext {
  showModal: () => void,
  hideModal: () => void,
}

export const DEFAULT_DATA: IContactModalContext = {
  showModal: () => undefined,
  hideModal: () => undefined,
}

const ContactModalContext = createContext(DEFAULT_DATA)
ContactModalContext.displayName = 'ContactModalContext'

export const Provider = ContactModalContext.Provider
export const Consumer = ContactModalContext.Consumer

export default ContactModalContext
