import { FC, ReactNode } from 'react'
import ReactModal from 'react-modal'
import { CollapsibleContextProvider } from 'services/Collapsible'
import { NavigationDrawerProvider } from 'services/MainNavigation'
import { ContactModalProvider } from 'services/ContactModal'
import Header, { HeaderSizeContextProvider } from 'services/Header'
import Footer from './Footer'
import Styles from './Layout.module.sass'

ReactModal.setAppElement('#__next')

interface LayoutProps {
  children?: ReactNode;
}

const Layout: FC<LayoutProps> = ({
  children,
}) => (
  <ContactModalProvider>
    <NavigationDrawerProvider>
      <HeaderSizeContextProvider>
        <CollapsibleContextProvider>
          <div className={Styles.wrapper}>
            <Header />
            {children}
            <Footer />
          </div>
        </CollapsibleContextProvider>
      </HeaderSizeContextProvider>
    </NavigationDrawerProvider>
  </ContactModalProvider>
)

export default Layout
