import { FC, ReactNode, useCallback, useMemo, useState } from 'react'
import { Provider, DEFAULT_DATA } from './ContactModalContext'
import { ContactModal } from 'organisms'

interface ContactModalProviderProps {
  children?: ReactNode;
}

const ContactModalProvider: FC<ContactModalProviderProps> = ({
  children,
}) => {
  const [modalVisible, setModalVisible] = useState(false)


  const contextData = useMemo(() => ({
    ...DEFAULT_DATA,
    showModal() { setModalVisible(true) },
    hideModal() { setModalVisible(false) },
  }), [])

  const onClose = useCallback(
    () => setModalVisible(false),
    []
  )

  return (
    <>
      <Provider value={contextData}>
        {children}
        {modalVisible && (
          <ContactModal onClose={onClose} />
        )}
      </Provider>
    </>
  )
}

export default ContactModalProvider
