import Link from 'next/link'
import { FC } from 'react'

import Styles from './LABadge.module.sass'

const LABadge: FC<unknown> = () => (
  <Link
    href='/lebensart-akademie'
    className={Styles.badge}
    title="LebensArt Akademie"
  />
)

export default LABadge
