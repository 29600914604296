import { IoIosMenu } from 'react-icons/io'
import { useDrawer } from 'services/MainNavigation'
import Styles from './MenuButton.module.sass'

const MenuButton = () => {
  const { toggleDrawerOpen } = useDrawer()

  return (
    <button
      onClick={toggleDrawerOpen}
      className={`menu-button ${Styles.button}`}
    >
      <div className="menu-button-inside">
        <IoIosMenu />
      </div>
    </button>
  )
}

export default MenuButton
