import { useEffect, useState } from 'react'

function getDimensions() {
  // Prevent issues on SSR
  if (typeof window === 'undefined') {
    return {
      width: 0,
      height: 0,
    }
  }

  const { innerWidth: width, innerHeight: height } = window

  return {
    width,
    height,
  }
}

const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState(getDimensions())

  useEffect(() => {
    // Prevent issues on SSR
    if (typeof window === 'undefined') return

    function handleResize() { setDimensions(getDimensions()) }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return dimensions
}

export default useWindowDimensions
