import classnames from 'classnames'
import Link from 'next/link'
import { FC, useMemo } from 'react'

import Styles from './NavigationContent.module.sass'

interface NavigationContentProps {
  drawer?: boolean,
}

const NavigationContent: FC<NavigationContentProps> = ({
  drawer = false,
}) => {
  const mainListClassNames = useMemo(() => classnames({
    [Styles.mainUl]: true,
    [Styles.mainUlHeader]: !drawer,
    [Styles.mainUlDrawer]: drawer,
  }), [drawer])

  const dropdownClassNames = useMemo(() => classnames({
    [Styles.dd]: true,
    [Styles.ddHeader]: !drawer,
    [Styles.ddDrawer]: drawer,
  }), [drawer])

  return (
    <>
      <ul className={mainListClassNames}>
        <li>
          <Link href="/angebot-privatpersonen">
            Angebote Privatpersonen
          </Link>
        </li>
        <li>
          <Link href="/angebot-firmen">
            Angebote Firmen
          </Link>
        </li>
        <li>
          <Link href="/seminare-workshops">
            Seminare &amp; Workshops
          </Link>
        </li>
        <li>
          <Link href="/service">
            Service
          </Link>
        </li>
      </ul>
      <div className={dropdownClassNames}>
        {!drawer && (
          <button className={Styles.moreButton}>
            Mehr&nbsp;
            <i className="fc-icon fc-icon-small fc-icon-plus fc-icon-dark-brown" />
          </button>
        )}
        <ul>
          <li className="separator">Angebote Privatpersonen</li>
          <li>
            <Link href="/lebensart-akademie">
              LebensArt Akademie
            </Link>
          </li>
          <li>
            <Link href="/angebot-privatpersonen/beratung-und-therapie-hpg-berlin-mitte">
              Beratung und Therapie (HeilprG)
            </Link>
          </li>
          <li>
            <Link href="/angebot-privatpersonen/angebote-fuer-senioren">
              Angebote Für Senioren
            </Link>
          </li>
          <li className="separator">Informationen</li>
          <li>
            <Link href="/informationen/ihr-coach">
              Ihr Coach
            </Link>
          </li>
          <li>
            <Link href="/blog">
              Blog
            </Link>
          </li>
          <li>
            <Link href="/informationen/fragen-und-antworten">
              Fragen und Antworten
            </Link>
          </li>
          <li className="separator">Sonstiges</li>
          <li>
            <Link href="/sonstiges/impressum">
              Impressum
            </Link>
          </li>
          <li>
            <Link href="/sonstiges/datenschutzerklaerung">
              Datenschutzerklärung
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}

export default NavigationContent
