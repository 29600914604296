import { SITE_URL } from './Config'

function canonicalUrl(path?: string): string {
  return [
    SITE_URL,
    (path || '').replace(/^\/+/, ''),
  ].join('/')
}

export default canonicalUrl
