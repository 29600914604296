import { useRouter } from 'next/router'
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import Drawer from 'react-modern-drawer'

import DrawerCloseButton from './DrawerCloseButton'
import NavigationContent from './NavigationContent'
import { DEFAULT_DATA, INavigationContext, Provider } from './NavigationContext'
import Styles from './NavigationDrawerProvider.module.sass'

interface NavigationDrawerProviderProps {
  children?: ReactNode | null,
}

const NavigationDrawerProvider: FC<NavigationDrawerProviderProps> = ({
  children,
}) => {
  const [drawerOpen, setVDrawerOpen] = useState(false)
  const router = useRouter()

  const setDrawerOpen = useCallback((value: boolean) => {
    setVDrawerOpen(value)
    document?.querySelector('body')?.classList.add('drawerOpen')
  }, [])

  const toggleDrawerOpen = useCallback(() => {
    console.log('TOGGLE DRAWER')
    console.log('current:', drawerOpen)

    setVDrawerOpen(!drawerOpen)
    if (drawerOpen) {
      document?.querySelector('body')?.classList.remove('drawerOpen')
    } else {
      document?.querySelector('body')?.classList.add('drawerOpen')
    }
  }, [drawerOpen])

  useEffect(() => {
    const handleRouteChange = () => {
      document?.querySelector('body')?.classList.remove('drawerOpen')
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  })

  const contextData = useMemo<INavigationContext>(() => ({
    ...DEFAULT_DATA,
    drawerOpen,
    setDrawerOpen,
    toggleDrawerOpen,
  }), [drawerOpen, setDrawerOpen, toggleDrawerOpen])

  return (
    <Provider value={contextData}>
      {children}
      <Drawer
        duration={200}
        open={drawerOpen}
        direction="right"
        customIdSuffix="fcdrawer"
        onClose={toggleDrawerOpen}
      >
        <DrawerCloseButton onClick={toggleDrawerOpen} />
        <div className={Styles.wrapper}>
          <NavigationContent drawer />
        </div>
      </Drawer>
    </Provider>
  )
}

export default NavigationDrawerProvider
