interface ResultData<T> {
  data?: T
  response: Response
}

function fetchApi<T>(
  input: RequestInfo, init?: RequestInit
): Promise<ResultData<T>> {
  return fetch(input, init)
    .then(async (response) => ({
      data: await response.json(),
      response,
    } as ResultData<T>))
}

export default fetchApi
