import {
  useEffect,
  useRef,
  useState,
} from 'react'
import useScroll from './useScroll'

/**
 * useScrollingUp custom react hook
 * @returns {boolean}
 */
const useScrollingUp = () => {
  const prevScroll = useRef<number>(0)
  const [scrollingUp, setScrollingUp] = useState(false)
  const scroll = useScroll()

  useEffect(() => {
    // Prevent issues on SSR
    if (typeof window === 'undefined') return

    const currScroll = scroll.y
    const isScrolled = prevScroll.current > currScroll

    setScrollingUp(isScrolled)

    prevScroll.current = currScroll
  }, [scroll?.y])


  // Return scroll value
  return scrollingUp
}

export default useScrollingUp
