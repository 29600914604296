import { FC } from 'react'
import { ContactForm } from 'organisms'
import { Modal } from 'atoms'
import Styles from './ContactModal.module.sass'

interface ContactModalProps {
  onClose?: () => void
}

const ContactModal: FC<ContactModalProps> = ({
  onClose = () => undefined
}) => (
  <Modal
    isOpen
    showHeader
    headerTitle="Kontakt"
    className={Styles.modal}
    onClose={onClose}
  >
    <ContactForm />
  </Modal>
)

export default ContactModal
