import {
  useCallback,
  useEffect,
  useState,
} from 'react'

interface Scroll {
  x: number,
  y: number,
}

/**
 * useScroll custom react hook
 * @returns {boolean}
 */
const useScroll = () => {
  const [scroll, setScroll] = useState<Scroll>(
    (typeof window !== 'undefined')
      ? { x: window.scrollX, y: window.scrollY }
      : { x: 0, y: 0 }
  )

  //
  // Handle scroll event
  //
  const handleScroll = useCallback(() => {
    if (typeof window === 'undefined') return
    setScroll({ x: window.scrollX, y: window.scrollY })
  }, [])

  //
  // Set up listeners
  //
  useEffect(() => {
    if (typeof window === 'undefined') return

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      if (typeof window === 'undefined') return
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  // Return scroll value
  return scroll
}

export default useScroll
