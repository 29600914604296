import each from 'lodash/each'
import isFunction from 'lodash/isFunction'
import {
  ComponentType,
  useRef,
  useState,
} from 'react'
import { useFormik } from 'formik'
import type {
  FormCaptchaProps,
} from 'atoms'
import { fetch, isPresent } from 'lib/utils'
import type {
  FormValues,
  ContactFormProps,
  ContactFormExposedProps,
} from './Types'

const INITIAL_VALUES: FormValues = {
  email: '',
  name: '',
  city: '',
  phone: '',
  mobile: '',
  captcha: undefined,
}

const GENERIC_ERROR = `Es tut uns Leid, leider ist ein Fehler passiert. Bitte melden Sie sich direkt an "info@frauencoach.jetzt".`

interface FetchResultData {
  valid?: boolean;
  errors?: { [key: string]: { message?: string } };
}

const withData = (Component: ComponentType<ContactFormProps>) => {
  const WithData = ({
    scrollUpOnSuccess = false,
    onSuccess = () => undefined,
    ...restProps
  }: ContactFormExposedProps) => {
    const captchaRef = useRef<FormCaptchaProps>()
    const [genericError, setGenericError] = useState<string | null>(null)
    const [submitted, setSubmitted] = useState(false)

    //
    // Create formik instance
    //
    const formik = useFormik<FormValues>({
      initialValues: INITIAL_VALUES,
      onSubmit: async (
        values,
        { setFieldError }
      ) => {
        const {
          valid,
          token,
          key,
          error,
        } = (await captchaRef.current?.validate?.()) || {}

        if (error) {
          setFieldError('captcha', error)
          return
        }

        if (!valid) {
          setFieldError('captcha', `Die Sicherheitsfragen wurden nicht korrekt beantwortet`)
          return
        }

        const result = await fetch<FetchResultData>(
          '/api/contact',
          {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              data: values,
              captcha: {
                token,
                key,
              }
            }),
          }
        )

        if (!result.response.ok) {
          setGenericError(GENERIC_ERROR)
          return
        }

        if (result.data?.valid) {
          formik.resetForm()
          setGenericError(null)
          setSubmitted(true)

          if (typeof window !== 'undefined' && scrollUpOnSuccess) {
            window.scrollTo(0, 0)
          }

          if (isFunction(onSuccess)) onSuccess()

          return
        }

        each(result.data?.errors, (error, key) => {
          setFieldError(key, error?.message)
        })
      },
      validate: (values) => {
        const errors: FormValues = {}

        if (!isPresent(values?.email)) {
          errors.email = 'bitte trage deine E-Mail-Adresse ein'
        }

        return errors
      }
    })

    //
    // Render component
    //
    return (
      <Component
        captchaRef={captchaRef}
        formik={formik}
        genericError={genericError}
        submitted={submitted}
        {...restProps}
      />
    )
  }

  // Set the display name as composition of name
  // and wrapped components name
  const wrappedDisplayName = Component.displayName || Component.name || 'Component'
  WithData.displayName = `WithData(${wrappedDisplayName})`

  // Return wrapper
  return WithData
}

export default withData
