import { createContext } from 'react'

export interface IHeaderSizeContext {
  headerHeight: number,
  setHeaderHeight: (height: number) => void,
}

export const DEFAULT_DATA: IHeaderSizeContext = {
  headerHeight: 0,
  setHeaderHeight: (_value) => undefined,
}

const HeaderSizeContext = createContext(DEFAULT_DATA)
HeaderSizeContext.displayName = 'HeaderSizeContext'

export const Provider = HeaderSizeContext.Provider
export const Consumer = HeaderSizeContext.Consumer

export default HeaderSizeContext
